import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import { DiscussionEmbed } from 'disqus-react';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoReddit,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
} from './templates.style';

import Sticky from 'react-stickynode';
import Cityset1 from "../components/citysetting/citytitle1"
import Styles from "./blog-post.module.css"
import Cesiumtab from "../containers/cesiumtab"




const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };
  return (
    <Layout3>
      
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />


        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <div>
          <div className={Styles.bar}>
            <div style={{display:"flex"}}>
              <div className={Styles.headerleft}>
                {/* <Cityset1 /> */}
                <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}><img style={{width:"100%",margin:"2px"}} src={"/sdgsicon/sdg_"+post.frontmatter.sdgs_no
+".png"}/></a>
              </div>




              <div className={Styles.headerno}>
                {post.frontmatter.no}
              </div>
              <div className={Styles.headertitle}>
                {post.frontmatter.title}
              </div>
              <div className={Styles.headerright}>
                <a className={Styles.button} href="/top">HOME</a>
              </div>
            </div>
          </div>
          </div>
        </Sticky>



      <BlogPostDetailsWrapper>
        <PostDetails
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          preview={
            post.frontmatter.cover == null
              ? null
              : post.frontmatter.cover.childImageSharp.fluid
          }
          description={post.html}
          imagePosition="left"
          honbun={post.frontmatter.honbun}
          chyusyaku={post.frontmatter.chyusyaku}
          hosoku={post.frontmatter.hosoku}    
        />


      
    <div>
    <Cesiumtab
      no={post.frontmatter.no}
      title={post.frontmatter.title}
      date={post.frontmatter.date}
      sdgs_no={post.frontmatter.sdgs_no}
      sdgs_main={post.frontmatter.sdgs_main}
      sdgs_subcat={post.frontmatter.sdgs_subcat}
      kou_tisou_pt={post.frontmatter.kou_tisou_pt}
      kou_tisou_chp={post.frontmatter.kou_tisou_chp}
      kou_tisou_sec={post.frontmatter.kou_tisou_sec}
      kou_tisou_sbsec={post.frontmatter.kou_tisou_sbsec}
      kou_tisou_title={post.frontmatter.kou_tisou_title}
      kou_titan_pt={post.frontmatter.kou_titan_pt}
      kou_titan_chp={post.frontmatter.kou_titan_chp}
      kou_titan_sec={post.frontmatter.kou_titan_sec}
      kou_titan_sbsec={post.frontmatter.kou_titan_sbsec}
      kou_titan_title={post.frontmatter.kou_titan_title}
      description={post.frontmatter.description}
      height_name={post.frontmatter.height_name}
      height_origin={post.frontmatter.height_origin}
      height_origin_note={post.frontmatter.height_origin_note}
      height_origin_url_name={post.frontmatter.height_origin_url_name}
      height_origin_url_name2={post.frontmatter.height_origin_url_name2}
      height_origin_url={post.frontmatter.height_origin_url}
      height_origin_url2={post.frontmatter.height_origin_url2}
      color_name={post.frontmatter.color_name}
      color_origin_note={post.frontmatter.color_origin_note}
      color_origin={post.frontmatter.color_origin}
      color_origin_url_name={post.frontmatter.color_origin_url_name}
      color_origin_url2_name={post.frontmatter.color_origin_url2_name}
      color_origin_url={post.frontmatter.color_origin_url}
      color_origin_url2={post.frontmatter.color_origin_url2}
      sokuchi={post.frontmatter.sokuchi}
      mesh_size={post.frontmatter.mesh_size}
      year_display={post.frontmatter.year_display}
      honbun={post.frontmatter.honbun}
      chyusyaku={post.frontmatter.chyusyaku}
      hosoku={post.frontmatter.hosoku}
      kml_file={post.frontmatter.kml_file}
      hanrei1_name={post.frontmatter.hanrei1_name}
      hanrei1_color={post.frontmatter.hanrei1_color}
      hanrei2_name={post.frontmatter.hanrei2_name}
      hanrei2_color={post.frontmatter.hanrei2_color}
      hanrei3_name={post.frontmatter.hanrei3_name}
      hanrei3_color={post.frontmatter.hanrei3_color}
      hanrei4_name={post.frontmatter.hanrei4_name}
      hanrei4_color={post.frontmatter.hanrei4_color}
      height_ratio={post.frontmatter.height_ratio}
       />
    </div>

<div className={Styles.datasheet}>

    <details open>
    <summary>詳細情報</summary>
    <div className={Styles.tablediv}>
    <div className={Styles.table}>
      <div>
        <h2>高さ</h2>
        <table>
          <thead>
            <tr>
              <th className={Styles.row1}></th>
              <th className={Styles.row2}>解説</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>名称</td>
              <td>{post.frontmatter.height_name}</td>
              </tr>
              <tr>
              <td>年次</td>
              <td>{post.frontmatter.year_display}</td>
              </tr>
              <tr>
              <td>出典</td>
              <td>{post.frontmatter.height_origin}</td>
              </tr>
              <tr>
              <td>定義</td>
              <td>{post.frontmatter.height_origin_note}</td>
              </tr>
              <tr>
              <td>詳細</td>
              <td><a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a><br/><a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a></td>
            </tr>
          </tbody>
        </table>
    </div>
    </div>


    <div className={Styles.tablediv}>
    <div className={Styles.table}>
      <div>
        <h2>色</h2>
      <table>
      <thead>
      <tr>
      <th className={Styles.row1}></th>
      <th className={Styles.row2}>解説</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      <td>名称</td>
      <td>{post.frontmatter.color_name}</td>
      </tr>
      <tr>
      <td>年次</td>
      <td>{post.frontmatter.year_display}</td>
      </tr>
      <tr>
      <td>出典</td>
      <td>{post.frontmatter.color_origin}</td>
      </tr>
      <tr>
      <td>定義</td>
      <td>{post.frontmatter.color_origin_note}</td>
      </tr>
      <tr>
      <td>詳細</td>
      <td><a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a><br/><a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a></td>
      </tr>
      </tbody>
      </table>
    </div>
    </div>

    </div>
    </div>
    </details>

    </div>

    <div>
    </div>

        <BlogPostFooter
          className={post.frontmatter.cover == null ? 'center' : ''}
        >
          {post.frontmatter.tags == null ? null : (
            <PostTags className="post_tags">
              {post.frontmatter.tags.map((tag: string, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                  {`#${tag}`}
                </Link>
              ))}
            </PostTags>
          )}
          <PostShare>
            <span>Share This:</span>
            <FacebookShareButton url={shareUrl} quote={post.excerpt}>
              <IoLogoFacebook />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
              <IoLogoTwitter />
            </TwitterShareButton>
{/*
            <PinterestShareButton
              url={shareUrl}
              media={urljoin(siteUrl, post.frontmatter.cover.publicURL)}
            >
              <IoLogoPinterest />
            </PinterestShareButton>
*/}
            <RedditShareButton
              url={shareUrl}
              title={`${post.frontmatter.title}`}
            >
              <IoLogoReddit />
            </RedditShareButton>
          </PostShare>
        </BlogPostFooter>
        <BlogPostComment
          className={post.frontmatter.cover == null ? 'center' : ''}
        >
          <DiscussionEmbed {...disqusConfig} />
        </BlogPostComment>
      </BlogPostDetailsWrapper>



      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => (
              <RelatedPostItem key={node.fields.slug}>
                <PostCard
                  title={node.frontmatter.title || node.fields.slug}
                  url={node.fields.slug}
                  image={
                    node.frontmatter.cover == null
                      ? null
                      : node.frontmatter.cover.childImageSharp.fluid
                  }
                  tags={node.frontmatter.tags}
                  sdgs_no={node.frontmatter.sdgs_no}
                />
              </RelatedPostItem>
            ))}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1170, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url2_name
        height_origin_url
        height_origin_url2
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url2_name
        color_origin_url
        color_origin_url2
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_name
        hanrei1_color
        hanrei2_name
        hanrei2_color
        hanrei3_name
        hanrei3_color
        hanrei4_name
        hanrei4_color
        height_ratio
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags
            cover {
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, maxHeight: 285, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url2_name
            height_origin_url
            height_origin_url2
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url2_name
            color_origin_url
            color_origin_url2
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
          }
        }
      }
    }
  }
`;

