import * as React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import _ from 'lodash';
import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  HonbunWrapper,
  PostDescription,
  PostTags,
} from './post-details.style';

import CesiumDiv from "../../pages/cesiumDiv";
import Sdgpanel from "../../containers/sdgs/panel";


type PostDetailsProps = {
  title: string;
  date?: string;
  preview?: any;
  description: any;
  tags?: [];
  className?: string;
  imagePosition?: 'left' | 'top';
  honbun: string;
  chyusyaku: string;
  hosoku: string;
};

const PostDetails: React.memo<PostDetailsProps> = ({
  title,
  date,
  preview,
  description,
  tags,
  className,
  imagePosition,
  honbun,
  chyusyaku,
  hosoku,
  ...props
}) => {
  const addClass: string[] = ['post_details'];

  if (imagePosition == 'left') {
    addClass.push('image_left');
  }

  if (className) {
    addClass.push(className);
  }

  return (
    <PostDetailsWrapper {...props} className={addClass.join(' ')}>
      {imagePosition == 'left' ? (
        <>
        
          {preview == null ? null : (
            <PostPreview className="post_preview">

<div>
              <div class="cp_menu">
<label for="cp_menu_bar1">SDGsから選ぶ</label>
<input type="radio" name="radio" id="cp_menu_bar1" class="accordion" />
<ul id="link1">
{/* <li><a href="/tags/sdgs-1"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_01.png"/></a></li>
<li><a href="/tags/sdgs-2"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_02.png"/></a></li> */}
<li><a href="/tags/sdgs-3"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_03.png"/></a></li>
<li><a href="/tags/sdgs-4"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_04.png"/></a></li>
<li><a href="/tags/sdgs-5"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_05.png"/></a></li>
{/* <li><a href="/tags/sdgs-6"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_06.png"/></a></li>
<li><a href="/tags/sdgs-7"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_07.png"/></a></li> */}
<li><a href="/tags/sdgs-8"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_08.png"/></a></li>
<li><a href="/tags/sdgs-9"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_09.png"/></a></li>
<li><a href="/tags/sdgs-10"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_10.png"/></a></li>
<li><a href="/tags/sdgs-11"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_11.png"/></a></li>
{/* <li><a href="/tags/sdgs-12"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_12.png"/></a></li>
<li><a href="/tags/sdgs-13"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_13.png"/></a></li>
<li><a href="/tags/sdgs-14"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_14.png"/></a></li>
<li><a href="/tags/sdgs-15"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_15.png"/></a></li>
<li><a href="/tags/sdgs-16"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_16.png"/></a></li>
<li><a href="/tags/sdgs-17"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_17.png"/></a></li> */}
{/* <li><a href="/sdgstheme"><img style={{width:"35px",margin:"2px"}} src="/sdgsicon/sdg_all.png"/></a></li> */}

</ul>
<label for="cp_menu_bar2">テーマから選ぶ</label>
<input type="radio" name="radio" id="cp_menu_bar2" class="accordion" />
<ul id="link2">
	<li><Link to="/tags/交通">交通</Link></li>
	<li><Link to="/tags/商業">商業</Link></li>
	<li><Link to="/tags/交通">交通</Link></li>
	<li><Link to="/tags/商業">商業</Link></li>
</ul>
</div>
              </div>
              <div>　</div>
              <div>　</div>
              <div>　</div>

<h2>良い例：福岡県糸島市</h2>
              <CesiumDiv
      dir="/kml"
      file="/sample.kml"
      kmlurl="/kml/sample.kml"
    />
              <p>小売業が公共交通利用圏に集積しており利便性が高い（なおこの画面はイメージです。現時点では全てのページで同じ情報が表示されています）</p>


              {/* <Img fluid={preview} alt={title} /> */}
            </PostPreview>
          )}
        </>
      ) : (
        ''
      )}

      {imagePosition == 'top' ? (
        <>
          <PostTitle>{title}</PostTitle>
          <PostDate>{date}</PostDate>
        </>
      ) : (
        ''
      )}

      {imagePosition == 'top' ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <Img fluid={preview} alt={title} />
            </PostPreview>
          )}
        </>
      ) : (
        ''
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        {/* {imagePosition == 'left' ? (
          <>


            <PostTitle>{title}</PostTitle>

            <PostDate>{date}</PostDate>
         
          </>
        ) : (
          ''
        )} */}



<HonbunWrapper>
        <div className="box26">
        <span className="box-title">解説</span>
        <p>{honbun}</p>
        </div>
        <div className="brackboard" >TRY!<br/>{chyusyaku}{hosoku}</div>
        </HonbunWrapper>

        <PostDescription
          dangerouslySetInnerHTML={{ __html: description }}
          className="post_des"
        />
        

        {tags == null ? null : (
          <PostTags>
            {tags.map((tag, index) => (
              <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  );
};

PostDetails.defaultProps = {
  imagePosition: 'top',
};

export default PostDetails;
